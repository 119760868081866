import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './Components/Navbar';
import HomePage from './Views/HomePage';
import About from './Views/About';
import StoryPage from './Components/StoryPage';
import BlogList from './Components/BlogList';
import BlogPost from './Components/BlogPost';
import ContactForm from './Components/ContactForm';
import MarkdownTest from './Components/MarkdownTest';
import ManageBlogPosts from './Components/ManageBlogPosts';
import AdminAuth from './Components/AdminAuth';
import { logAnalyticsEvent } from './firebase'; // Add this import

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, [pathname, hash]);

  return null;
}

function AnalyticsWrapper() {
  const location = useLocation();

  useEffect(() => {
    logAnalyticsEvent('page_view', {
      page_path: location.pathname,
      page_title: document.title
    });
  }, [location]);

  return null;
}

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <AnalyticsWrapper />
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/story/:id" element={<StoryPage />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route 
            path="/add-blog" 
            element={
              <AdminAuth>
                <MarkdownTest />
              </AdminAuth>
            } 
          />
          <Route 
            path="/manage-blog" 
            element={
              <AdminAuth>
                <ManageBlogPosts />
              </AdminAuth>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;