import React from 'react';
import './About.css';
import aboutImage from '../Assets/Images/about_attachment.webp';

const About = () => {
 return (
   <div className="about-container">
     <div className="about-content">
       <h2 className="about-subheading">A Free Spirit? - Not Quite</h2>
       <div className="about-text">
         <p>
         Let’s call a a spade a spade. I’m one of life’s fuck ups
         </p>
         <p>
         I’m a middle aged man, pretty smart, nice personality, reasonably attractive, talented, fit and healthy. I have a few close friends, I know how to get on with people and have no difficulty in finding girlfriends.
         </p>
         <p>
         But I under achieve and relationships never last. People think it’s because I am a free spirit. Maybe. But the truth is, I am 'Attachment Avoidant' - which means I’m a walking disaster for any woman who wants to get close. I’m incapable of showing love and passion beyond the bedroom and show just enough affection to keep a woman wanting more. And that's hurt a lot of lovely people.
         </p>
         <p>
         But when my last relationship came to an end, and the way it came to an end - the fact that I did not even realise it had come to an end - was unexpectedly devastating. One minute life was great, and then the next, it definitely wasn’t. And I realised - I only fall in love with women who don’t want me, who make me feel bad about myself, who make me jealous, who make me suffer. And this last one, opened my eyes to what a beautiful relationship should look like.
         </p>
         <p>
         So, this website, this blog, or whatever it is, I just want to everything inside of me OUT - to dump, (or rather neatly place) all of my thoughts, memories, fantasies, feelings, learnings and insights into the world, in the hope that I figure out who I am, why I’m like this and to become a better man - for me and the people I love.
         </p>
       </div>
     </div>
     <div className="about-image-container">
       <img 
         src={aboutImage}
         alt="Abstract art with hearts and geometric shapes" 
         className="about-image"
       />
     </div>
   </div>
 );
};

export default About;