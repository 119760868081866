import React from 'react';
import BlogDashboard from './BlogDashboard';
import './styles.css';

const ManageBlogPosts = () => {
  return (
    <div className="manage-blog-container">
      <BlogDashboard />
    </div>
  );
};

export default ManageBlogPosts;