import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import StorySection from '../Components/StorySection';
/*import { stories } from '../data/stories';*/
import './HomePage.css';

const stories = [
    {
      id: 1,
      title: "Love's Embrace",
      description: "The radiation of pure love, works magic on others",
      imageUrl: `/images/love_embrace1_bw_stories.webp`,
    },
    {
      id: 2,
      title: "Writer",
      description: "A story of 'not' letting go",
      imageUrl: `/images/writer2_bw_stories.png`,
    },
    {
      id: 3,
      title: "Sundays",
      description: "'Sundays are always special, but today is a special date",
      imageUrl: `/images/sundays3_bw_stories.png`,
    },
    {
      id: 4,
      title: "Salsa",
      description: "Coming soon.",
      imageUrl: `/images/salsa1_bw_stories.png`,
    },
    {
      id: 5,
      title: "Mine",
      description: "Coming soon",
      imageUrl: `/images/mine1_stories.png`,
    },
    {
      id: 6,
      title: "Slave",
      description: "Coming soon",
      imageUrl: `/images/slave1_bw_stories.webp`,
    },
    {
      id: 7,
      title: "Pregnant",
      description: "Coming soon",
      imageUrl: `/images/pregnant1_bw_stories.webp`,
    },
    {
      id: 8,
      title: "Honeymoon",
      description: "Coming soon.",
      imageUrl: `/images/honeymoon2_bw_stories.png`,
    }
];

const HomePage = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#stories-section') {
            const element = document.getElementById('stories');
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            }
        }
    }, [location]);

    return (
        <div className="home-page">
            <div className="content-wrapper">
                <Header />
            </div>
            <section id="stories-section">
                {stories.map((story, index) => (
                    <StorySection
                        key={story.id}
                        title={story.title}
                        description={story.description}
                        imageUrl={story.imageUrl}
                        storyId={story.id}
                        isImageLeft={index % 2 !== 0}
                    />
                ))}
            </section>
        </div>
    );
};

export default HomePage;