import { db } from '../firebase';
import { collection, addDoc, updateDoc, doc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { parseMarkdown } from './markdownParser';

export const savePost = async (markdown, options = {}) => {
  try {
    const { metadata, content } = parseMarkdown(markdown);
    console.log('About to save - metadata:', metadata);
    console.log('About to save - content:', content);   
    
    const post = {
      ...metadata,
      status: options.status || metadata.status,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      publishedAt: options.status === 'published' ? serverTimestamp() : null,
      scheduledFor: options.scheduledFor ? Timestamp.fromDate(options.scheduledFor) : null,
      content
    };

    console.log('Final post object:', post);
    
    const docRef = await addDoc(collection(db, 'mloy-content'), post);
    return { id: docRef.id, ...post };
  } catch (error) {
    console.error('Error saving post:', error);
    throw error;
  }
};

export const updatePost = async (postId, markdown, options = {}) => {
  try {
    const { metadata, content } = parseMarkdown(markdown);
    console.log('About to update - metadata:', metadata); // Add this log
    console.log('About to update - content:', content);   // Add this log

    const updates = {
      ...metadata,
      content,
      updatedAt: serverTimestamp()
    };

    if (options.status) {
      updates.status = options.status;
      if (options.status === 'published') {
        updates.publishedAt = serverTimestamp();
        updates.scheduledFor = null;
      } else if (options.status === 'scheduled' && options.scheduledFor) {
        updates.scheduledFor = Timestamp.fromDate(options.scheduledFor);
        updates.publishedAt = null;
      }
    }

    console.log('Final update object:', updates); // Add this log
    
    const postRef = doc(db, 'mloy-content', postId);
    await updateDoc(postRef, updates);
    return { id: postId, ...updates };
  } catch (error) {
    console.error('Error updating post:', error);
    throw error;
  }
};

export const publishScheduledPost = async (postId) => {
  try {
    const postRef = doc(db, 'mloy-content', postId);
    await updateDoc(postRef, {
      status: 'published',
      publishedAt: serverTimestamp(),
      scheduledFor: null,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error publishing scheduled post:', error);
    throw error;
  }
};