import React from 'react';
import './StorySection.css';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const StorySection = ({ title, description, imageUrl, isImageLeft, storyId }) => {
  const contentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };

  return (
    <section className={`story-section ${isImageLeft ? 'image-left' : ''}`}>
      <motion.div 
        className="story-content"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={contentVariants}
      >
        <div className="content-wrapper">
          <h2>{title}</h2>
          <p>{description}</p>
          <Link to={`/story/${storyId}`}>
            <motion.button 
              className="read-story-btn"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Read Story
              <span className="arrow">→</span>
            </motion.button>
          </Link>
        </div>
        {/* Decorative Elements */}
        <div className="decorative-circle top-left"></div>
        <div className="decorative-circle bottom-right"></div>
      </motion.div>

      <motion.div 
        className="story-image-container"
        initial={{ opacity: 0, x: isImageLeft ? -20 : 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <motion.div 
          className="story-image"
          style={{backgroundImage: `url("${imageUrl}")`}}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <div className="image-overlay"></div>
          <div className="decorative-corner top-left"></div>
          <div className="decorative-corner bottom-right"></div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default StorySection;