export const stories = [
    {
      id: 1,
      title: "Love's Embrace",
      imageUrl: "/images/love_embrace1_bw_stories.webp", 
      content: 
      [
        "We are where we used to work",
        "G is giving a massage to a client",
        "There is you and me. You are in your uniform, navy top, in the room with her.",
        "We are sat on the floor together and we begin to kiss.",
        "The kissing becomes more intense and I move my hands through your hair, then caress your body.",
        "Two young women, employees that we know in the room",
        "Beside us on one side is K, on the other I",
        "We talk as we kiss, oblivious to those around us. We are really getting into it.",
        "We talk about you moving to Malaga. How a friend of yours went for a week and didn’t like it.",
        "Then suddenly, we realise we are going to far, that there are people around us.",
        "We apologise but it’s too late. We can’t stop, our desire to kiss again is insatiable.",
        "The client with G, a small young Asian man, gets of the bed and walks past us out of the room, smiling, a pure smile of admiration, a nod to our love, our happiness.",
        "K is lying down next to us now, her hand down her pale yellow panties enjoying, mesmerised, watching",
        "I is kneeling on the other side, entranced. She pulls her top up and touches her large breast. They are both in ecstasy watching us, absorbing the passionate energy that radiates from us, filling the room, they are helpless to stop.",
        "And you and I continue. We don’t undress, but our bodies are together, lost in our own world of joy and closeness.",
        "I wake up, I decide to I have to be closer to you, to move. We have to be together.",
        "Then, suddenly, I realise with my heart still pounding, that this is not real.",
        "You are gone.",
        "But here, in my mind and my heart, I can have you always."
        ]
      
    },
    {
      id: 2,
      title: "Writer",
      imageUrl: "/images/writer2_bw_stories.png",
      content: [
        "He said goodbye to his Dad and headed to the airport. It had been a testing 6 months but his Dad got through it. He didn’t think his Dad was going to make it but time and time again he bounces back. Caring had been tougher than he realised too. But all was now well and good. He knew his Dad wouldn’t be here much longer, that was just the law of averages and he had come to accept that. Now it was time to think of himself and he needs to rebuild.",
"5 years later.",
"His mind is focused. He's working, doing what he loves and what he had to do - write. He remembers how they had talked in the chats about writing. He'd come up with the idea, originally for her, but it was she who had given him some advice: 'But maybe starting to write in a more intuitive way and see how it develops and takes shape - could be interesting - I do think you need to be more natural with these things sometimes,' she had said.",
"Work had become difficult after they stopped seeing each other. Unable to focus on technical things anymore, he channelled his more creative energies into writing and editing. He had done rather well for himself. He didn't need to work late at night anymore. With deep focus, he could easily achieve all he needed in plenty of time. And with her in his heart, his creative juices just flowed. He regretted spending so much time working when she had needed him.",
"It's morning now. He always starts early these days. He reads the last lines of his short story, from a new collection - Fantasies: 'Carlos leaves and they are alone again. It's a cold winter's day and they walk by the river hand in hand. The river is icy and a chilling breeze is blowing through the air. They don't say much, they don't need to. Their hearts are glowing; the night has strengthened their trust, their bond, and they have never been so in love.'",
"He wondered what she would think about the story and the new Fantasies collection. He didn’t think himself a good writer. But she inspired him to write. At first, writing was just a need to clear his mind, but it became a way to recover, to heal. And his stories had become incredibly popular - If she enjoyed them, perhaps other people would. And they did. His characters were varied but it was always her that inspired his writing and he imagines her reading them, wondering what she would think.",
"He closes his old trusty MacBook and looks out of the window. The sun is still rising. He can see a small boat trying to moor in the harbour, an old man with a stick trying to guide it. He smiles, wondering why old men in Spain feel the need to get involved in things that do not concern them. Maybe he would be like that one day?",
"He decides to go out for a short run, and get some fresh air. He changes, puts on his trainers and heads downstairs. As he steps out the door, the buzz of Spanish daily life hits him. He enjoys this moment each day. And he smiles, knowing he is in the right place. He tries to run as often as his legs will allow. He can escape for a while, think about the book, think about her, think about his old Dad who is now gone, think about whatever he likes really. It’s not important, he allows thoughts to just come and go.",
"He heads out in the direction of the beach before heading up the trail. The first part is quite tough and by the time he reaches the top, he can feel his heart beating from the exertion, his lungs gasping for air. But the view is amazing. In the distance, across the sea, he can see the sun rising above Ibiza and wonders if she ever got around to visiting her sister. He puts the thought out of his mind and continues, pushing harder, enjoying his body's power and energy. The air is warm but not too warm yet, it's just right.",
"He gets back home and showers and heads out again for a coffee. He's meeting his friend, C. They meet up regularly now. She makes him smile. She's always doing something new, and got a new adventure to tell - even if the previous one only finished yesterday. Today, she's come up with the idea of setting up a small tourist guide business aimed at the increasing number of Chinese tourists coming to visit the city on the big ships.",
"'Mucho dinero D, mucho dinero,' she says.",
"He smiles. He loves her very much, she is his best friend. He always wishes he could have talked to her about C. He really wanted her to understand him and know that there was nothing to fear. But he understands her now. He too felt fear and discomfort when she told him she was going to meet an old friend. He felt it when he visited her the first time in Bratislava, before he had even kissed her, and he felt it again when she mentioned him in the chats. He knew what might happen, and it did.",
"After finishing his lunch and walk with C, he heads back home. He crosses the plaza and decides to stroll through the old town. He's in no rush today. He smiles at the thought of C, with her bright yellow hat guiding tourists through the streets. 'Mucho dinero, mucho dinero,' hehe, he chuckles. Bless.",
"As he is walking, he sees a young girl with blonde hair. She reminds him of someone he once knew. Then he sees another, slightly taller one. They are older now, teenagers, but it's definitely them. Then he sees her. His heart jolts. She is still as beautiful as ever. An overweight man is standing with them. A little boy is playing hide and seek with him. The man picks the little boy up and puts him on his shoulders and the little boy starts pulling his hair much to his discomfort. She tells her husband to be careful. That forced him to smile. Some things never change, he thought, as motherly as ever.",
"He decides to take the side street. He doesn't want to see her. But too late, A sees him and comes running up to him, closely followed by a not so little L. My goodness, how they have grown.",
"'D, D,' they shout, smiling.",
"'Look mummy, it's D!'",
"She comes towards him smiling, full of life.",
"'I can't believe it, what are you doing here?' she asks.",
"He explains that this is where he lives now. She introduces him to her husband and they greet each other. He seems nice enough, very friendly, normal. Just as she wanted. And she excitedly introduces me to little Janko who's 4.",
"'Hello little Janko,' he says.",
"Little Janko smiles and pulls his father's hair harder forcing him to wince. They are on holiday down the coast but are visiting the city for the weekend. She seems happy and he is pleased for her. She invites him to come and have dinner with them but he lies and says that he has a meeting. She tells him how nice it is to see him again and they promise to stay in touch. They never do. It is lovely to see the girls again, so grown up, healthy, happy.",
"But it is not his L. His L lives in his heart now, and in the chats. He says goodbye and continues his walk. He needs to go home now, to write.",
"As he brushes his teeth in the mirror that night, he sees himself handsome and healthy. He knows he can meet someone new if he would allow his heart to open up to someone else, but he won’t. He has flings of course, but he won’t let anyone get close to him again. He can feel the melancholy returning. He is used to it now so that is ok. It’s become a tool that guides him. This is the life he has chosen and it’s brought him success in his career.",
"In bed he lies back, and closes his eyes. They are walking hand in hand up the hill in the forest, like the last time when he visited. She is in the kitchen, cooking, and he comes up behind her, cuddles her, kisses her neck and whispers something in her ear that makes her melt. She is on top of him, riding him and he reaches up and feels her breasts as they make love over and over. As the thoughts and dreams merge into one, he sees them lying next to each other. He kisses her deeply, as he does every night.",
"'Knock knock,' he says,",
"'Who’s there?'",
"'Olive,'",
"'Olive who?'",
"'Oliveyouxx.'",
"She smiles like always and snuggles in to him, her head on his chest and they fall asleep.",
"My leg over yours.",
"Your leg over mine.",
"The End."
        ]
        
    },
    {
      id: 3,
      title: "Sundays",
      imageUrl: "/images/sundays3_bw_stories.png",
      content: [
        "We live in this world in our physical form. But you can never be truly happy unless you learn to listen to your soul. (L, Jan 6th)",

"The now",
"She told him many things about Love. She tried to help him see the beauty of it.",
"But that was not his world. His world was full of doubt, low self-esteem, wondering what to do next, worrying if he will hurt people, fretting over his choices or making the right decisions. And this he calls freedom! ",
"Round and round the thoughts go, crushing his chances of real happiness and a life full of meaning. He prides himself on not having feelings and doesn’t allow himself to love or be loved. The Robot. She was right, Freedom is about sharing with the right person.",
"She talks with her sister about the dangers of falling for him. Her sister thinks a man can change, but L has other ideas. At 50, she says, he will most likely stay the same - ‘You are who you are and that is not going to change. We both know this’ she says. It seems a leopard cannot change its spots. He knows that she is almost always right. But the leopard wants to change. Maybe it is too late.",
"",
"6 months later:",
"It's a cold spring Sunday morning and they are out running together. They got up extra early today to be together - it's been exactly 6 months since she agreed to him coming, and he got an apartment in the city where she lived. He understood what she meant - that long distance was not going to work and that they needed quality time together to give their relationship a second chance. He didn't mind moving now. He loved her, moving was simple.",
"Since he arrived, they had dated, like a real couple and were enjoying each other's company. She was still concerned about how the kids would feel and wanted to take that step gradually. Getting the apartment was perfect. She'd come around after work sometimes and enjoy her coffee there (and perhaps a kiss or two). He bought a coffee machine like hers so she'd have it just the way she liked it. Of course, she was busy with work and the kids but they found time for each other. They even managed to go on their 'kebab' date. Their love began to flourish like it was meant to. But there was no rush.",
"Sometimes he'd visit her at her house. The kids were a bit suspicious at first, but he was happier now and that happiness shined through; which made them very comfortable with him - it was like the first week that he met them all over again. This took a great weight off L’s mind. They were always her priority and that would not change - but love made things easier.",
"They continue running around the track until they are tired. He had become fit and strong again so he has to slow down so she could keep up;. He didn’t mind though. He loves running with her. He didn't work so much either now unless he wanted to. But it was never an issue to find time for her, - whenever she wanted - and with pleasure. She never has to fight for his attention. He kisses her.",
"They head back to the house. The girls were just waking up. ‘Look who I found while running,’ L says. ‘Morning kids, ‘ says D. ‘Morning’ they yawn.",
"This morning Auntie K and her partner are taking them rock climbing out of town. The kids always did some kind of activity on a Sunday morning these days, giving mum some much needed 'me' time. As the kids ate their breakfast, D cut the grass. It was the first time it had needed cutting since the winter, and L was surprised to hear the sound of the lawnmower going in the distance. She came out smiling and kissed him. She also noticed that he had missed a bit.",
"But Sunday mornings had become special in other ways too. The couple had a very powerful sexual chemistry, something truly special. When reading the chats, D had felt the energy between them, which was still very much alive on the page as he read. It's why he had to reach out to her, why he couldn’t just accept the end. The connection they had when making love was incredible that went way beyond the physical and extended beyond the bedroom. It was as though their very essence of being, their souls were connected at those moments; it was so special, every time. The trust and love they felt during intimacy had real deep meaning to each of them.",
"They made love often, and everywhere - the bedroom, the sofa, the bath, upstairs, his place, the car and once even outside in the woods while out for a walk, up against a tree while a frog was watching. They had passionate sex, quick sex, slow sex, late sex and early sex but Sunday mornings had become a bit different. Sundays were especially about her. She was the centre of his world now, he wants her to feel how special she is to him.",
"After mowing the lawn, he says farewell to the kids and goes home to shower. L packs the kids off with Aunty K and finishes up in the kitchen and goes for a shower herself. He returns an hour or so later with a small bunch of flowers, nothing over the top, some champagne and chocolates. They had passed the first test - 6 months. She had told him that she wasn't really into flowers but today was different. She loves them, expects them and accepts them with a smile while he pops a chocolate into her mouth. She looks so beautiful to him right there and then, dressed only in her white bathrobe, her hair down and the rays of sunshine coming through the blinds catching her face.",
"She goes to get a couple of glasses and he pours the champagne. They clink their glasses and drink, smiles of knowing and desire on their faces. He pulls the belt of her dressing gown to draw her closer to him and kisses her, deeply and passionately as though it were the first time they had kissed. He kisses her cheeks, her neck, breathes soft whispers in her ear - 'I love you, my L'. She sighs, giving herself to him. She can feel his firmness pressing against her through her dressing gown and he knows she enjoys it, knowing he belongs to her now. She reaches to feel him, through his trousers and he kisses her even more, feeling her tongue dance on his, their lips never parting, their love flowing through them.",
"He pulls on her gown belt revealing her naked beauty beneath and he explores her body with his hand - her tummy, her waist, her firm little behind. He remembers the first time when he touched her here, feeling like the luckiest man on earth. When his hand reaches her breasts he stops and plays with her firm aroused nipple. All the while he is kissing her, adoring her. He takes off her dressing gown and gently lays her back on the bed, viewing her naked before him like the first time. Sundays were so much like the first time. She wants to see him too. He removes his shirt and he lets her undo his trousers. Because she liked doing that. He was very big and hard and she playfully licks the tip of him. He is always pleased watching how much she enjoys doing that. The pure desire in her eyes.",
"He lays her back on the bed once more and moves on top of her. He wants to feel her body beneath his, so their whole bodies are touching. He takes her hands with his, their fingers interlocked, and kisses her once more. He could kiss her forever, his princess. They look, staring at each other, both knowing this was all meant to be. All those months ago, she hadn’t been wrong, he had been blind. She opens her legs a little for him and he can feel his hardness touching her pussy. They stay like that, enjoying the desire, the anticipation, the Love. He wants her to feel so special today, loved, needed, safe,… desired.",
"He begins moving down her body, trying to kiss every single inch of her as he goes. There was no rush. They are in love and time is meaningless today, Sunday. He reaches her breasts, teasing her nipples with his tongue before gently biting them. He loved her breasts, the way they look, the way they feel and how she touches them when making love. He continues his journey down her body, kissing all the time, her tummy, her abdomen, her hips, her thighs before arriving at her beautiful moist little pussy. He tries to absorb all of her sex in his senses, looking, touching, breathing, smelling, tasting. His mind is alight with passion for this woman. As his tongue explores he can sense her movements, slowly enjoying the sensation of his mouth. He licks her up and down, putting his tongue inside of her. He runs his hands along her thighs and tummy and he continues.",
"She begins moving slightly quicker and he can hear the arousing soft sighs of pleasure. He moves his tongue a little, focusing on her clitoris and inserted a couple fingers inside of her. He knows she likes this. His tongue moves more quickly. Her pleasure is his pleasure. She’s very wet now and he begins sliding his fingers in and out of her more quickly. He increases the speed of his tongue, feeling her respond to it, her hips moving up a little, her breathing quicker and she begins to orgasm. He feels her shudder and she reaches a huge orgasm before gently letting her body come to rest. He loves hearing and seeing her climax.",
"He kneels on the bed beside her to see her face, slightly reddened by the exertion. Her hair has become damp with sweat. He kisses her again. Their mouths sharing each other once again. L, my Love. They stay like that for a while just looking at each other. He touches her face with his hand, her adorable cheeks, her chin. He allows his fingers to move lightly across her neck. His love is so pure, uncomplicated. There is just her and him right now.",
"She moves her hand across his thigh, to feel him. Moving slowly, enjoying the touch of his skin. She finds her friend without taking her eyes from his. She feels how big and hard and ready for her he is. She enjoys this feeling.. She strokes him a little and tells him she wants him.",
"'I want you to finish inside me today', she says",
"She always tells him how she wants him to finish, he is grateful for it, loves it and it excites him even more hearing her say it. He moves on top of her once more -",
"'I am going to put myself inside your pussy now L, you want that don’t you'",
"They talk more now than the first time, both love hearing each other say what they want and what they are going to do to each other. It’s erotic. But it’s more than that. It’s the intimacy they share. Nobody else but them will ever hear these words.",
"'Yes, I want you D, put your big hard cock inside of me, take me -anyway you want'",
"He enters her slowly and begins sliding himself in and out. He can feel her deep inside, the pleasure is so good it’s almost unbearable. She sighs and they look into each other's eyes again. He bites her lips and she bites his as they make love so deep and passionately, destined for each other, never looking away. He goes harder and faster and deeper still. The sounds of their love adds to their excitement and she moans with pleasure as he begins to come. He wants her to have everything inside of him - his sperm, his love, his soul. She begins to climax again too and they finish in a crescendo of moans of lust, pleasure and Love.",
"He stays inside of her, sharing their bodies, and they lay there. Hearts beating together as one. It’s perfect. She smiles, she knows she is loved. She wanted to feel owned, and he wanted to belong to her.",
"He moves to the side to let her breathe and she rests her head on his chest. He strokes her soft hair and feels grateful for this moment of being with her. He tells her with all his heart that he loves her.",
"But she is already asleep",
"Her leg over mine",
"Epilogue",
"It's a freezing cold night, dark, grey and miserable. He stands there with M, her adorable cockapoo. The dog is shivering and looking up at him, obviously enjoying the weather as much as he is. To his surprise, she asks him a question.",
"'Why on earth did you move here from sunny Spain with its beaches and palm trees?' she asks as though he were some kind of idiot.",
"He smiles. 'Simple really M, Love.'",
"'Besides,' he continues, 'we can always move to Spain when the girls are at University at Cambridge and Oxford.'",
"'What about me?' she asks.",
"'You can come too, my dear friend.'",
"She wags her tail merrily and they walk back home.",
"The End"
        
      ]
    },
    {
      id: 4,
      title: "Salsa",
      imageUrl: "/images/salsa1_bw_stories.webp",
      content: [
        "Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.",
        "Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.",
        "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
      ]
    },
    {
      id: 5,
      title: "Mine",
      imageUrl: "/images/mine1_stories.png",
      content: [
        "Nulla facilisi. Maecenas aliquam faucibus elit, at consequat nulla dapibus non. Praesent in leo ut nulla varius malesuada.",
        "Vivamus vehicula, nisi vel ultricies tincidunt, nulla nunc faucibus nunc, vel ultricies nunc nisi vel nunc. Sed euismod, nunc vel ultricies tincidunt, nulla nunc faucibus nunc, vel ultricies nunc nisi vel nunc.",
        "Donec auctor, nunc vel ultricies tincidunt, nulla nunc faucibus nunc, vel ultricies nunc nisi vel nunc. Sed euismod, nunc vel ultricies tincidunt, nulla nunc faucibus nunc, vel ultricies nunc nisi vel nunc.",
      ]
    },
    {
      id: 6,
      title: "Slave",
      imageUrl: "/images/slave1_bw_stories.webp",
      content: [
        "Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Nullam id dolor id nibh ultricies vehicula ut id elit.",
        "Etiam porta sem malesuada magna mollis euismod. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.",
        "Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.",
      ]
    },
    {
      id: 7,
      title: "Pregnant",
      imageUrl: "/images/pregnant1_bw_stories.webp",
      content: [
        "Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
        "Nullam quis risus eget urna mollis ornare vel eu leo. Nullam id dolor id nibh ultricies vehicula ut id elit. Sed posuere consectetur est at lobortis.",
        "Aenean lacinia bibendum nulla sed consectetur. Maecenas sed diam eget risus varius blandit sit amet non magna. Donec ullamcorper nulla non metus auctor fringilla.",
      ]
    },
    {
      id: 8,
      title: "Honeymoon",
      imageUrl: "/images/honeymoon2_bw_stories.png",
      content: [
        "Curabitur blandit tempus porttitor. Nullam quis risus eget urna mollis ornare vel eu leo. Nullam id dolor id nibh ultricies vehicula ut id elit.",
        "Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.",
        "Donec ullamcorper nulla non metus auctor fringilla. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras justo odio, dapibus ac facilisis in, egestas eget quam.",
      ]
    },
  ];
