import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Timestamp } from 'firebase/firestore';
import { logAnalyticsEvent } from '../firebase';  // Add this import
import './BlogPost.css';

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const { id } = useParams();

  const formatDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      const date = timestamp.toDate();
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
    return 'No date';
  };

  // Original useEffect for fetching post
  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(db, 'mloy-content', id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        const possibleImageFields = ['image', 'imageUrl', 'imageURL', 'img', 'imgUrl', 'imgURL'];
        let imageUrl = null;
        for (let field of possibleImageFields) {
          if (data[field]) {
            imageUrl = data[field];
            break;
          }
        }
        
        if (imageUrl) {
          imageUrl = imageUrl.replace(/^\/Images\//, '/images/');
        }
        
        setPost({ 
          id: docSnap.id, 
          ...data,
          date: data.publishedAt ? formatDate(data.publishedAt) : 'No date',
          imageUrl: imageUrl
        });
      } else {
        console.log("No such document!");
      }
    };

    fetchPost();
  }, [id]);

  // New useEffect for Google Analytics tracking
  useEffect(() => {
    if (post) {
      logAnalyticsEvent('blog_post_view', {
        post_id: post.id,
        post_title: post.title,
        post_category: post.category,
        read_time: post.readTime,
        post_date: post.date
      });
    }
  }, [post]);

  if (!post) return <div>Loading...</div>;

  return (
    <article className="blog-post">
      <h1 className="blog-post-title">{post.title}</h1>
      <div className="blog-post-meta">
        <span className="blog-post-date">{post.date}</span>
        <span className="blog-post-read-time">{post.readTime} min read</span>
        <span className="blog-post-category">{post.category}</span>
      </div>
      
      <div className="blog-post-image">
        {post.imageUrl && (
          <img 
            src={post.imageUrl}
            alt={post.title}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/placeholder-image.jpg';
            }}
          />
        )}
      </div>
      
      <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: post.content }} />
    </article>
  );
};

export default BlogPost;