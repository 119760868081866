import React, { useState } from 'react';
import { db } from '../firebase';
import { addDoc, collection, updateDoc, doc } from 'firebase/firestore';
import './ContactForm.css';


const ParticleWave = () => {
  const particleTypes = [
    { size: '5px', color: 'rgba(213, 158, 149, 0.8)', speed: '6s' },  // Pink with increased opacity
    { size: '5px', color: 'rgba(193, 225, 193, 0.8)', speed: '6s' },  // Green with increased opacity
  ];

  return (
    <div className="wave-container">
      <div className="particle-wave">
        {[...Array(500)].map((_, index) => {
          const type = particleTypes[Math.floor(Math.random() * particleTypes.length)];
          const randomY1 = Math.random() * 40 - 20;
          const randomY2 = Math.random() * 40 - 20;
          const randomY3 = Math.random() * 40 - 20;
          
          return (
            <div 
              key={index} 
              className="particle"
              style={{
                '--delay': `${5 + Math.random() * 5}s`,
                '--position': `${Math.random() * 100}%`,
                '--y1': `${randomY1}px`,
                '--y2': `${randomY2}px`,
                '--y3': `${randomY3}px`,
                width: type.size,
                height: type.size,
                backgroundColor: type.color
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const webhookUrl = process.env.REACT_APP_WEBHOOK_URL;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ type: 'pending', message: 'Sending message...' });

    try {
      // 1. Store in Firestore
      const docRef = await addDoc(collection(db, 'messages'), {
        ...formData,
        timestamp: new Date(),
        processed: false
      });

      // 2. Send to webhook (if configured)
      if (webhookUrl) {
        try {
          console.log('Sending to webhook:', webhookUrl);
          
          const webhookPayload = {
            ...formData,
            messageId: docRef.id,
            timestamp: new Date().toISOString(),
            source: 'website_contact_form'
          };
          
          console.log('Webhook payload:', webhookPayload);
      
          const webhookResponse = await fetch(webhookUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify(webhookPayload)
          });
      
          console.log('Webhook status:', webhookResponse.status);
          console.log('Webhook response:', await webhookResponse.text());
      
          if (!webhookResponse.ok) {
            throw new Error(`Webhook failed: ${webhookResponse.status} ${webhookResponse.statusText}`);
          }
      
          await updateDoc(doc(db, 'messages', docRef.id), {
            processed: true,
            webhookTimestamp: new Date()
          });
        } catch (webhookError) {
          console.error('Webhook error details:', webhookError);
          // Continue execution - don't break the form submission
        }
      }
      
      // 3. Send email (your existing email logic)
      await addDoc(collection(db, 'mail'), {
        to: formData.email,
        bcc: "dan@danquixote.ai",
        message: {
          subject: "💌 Thank you for your message",
          text: `Got your message. I'll try to respond asap. Pekny den, D`,
          html: `
            <div style="font-family: Helvetica, Arial, sans-serif; max-width: 500px; margin: 0 auto; background-color: #ffffff;">
              <div style="background-color: #333; color: #c1e1c1; padding: 30px; border-radius: 3px;">
                <p style="font-size: 16px; margin-bottom: 15px;">Hi ${formData.name},</p>
                <p style="font-size: 16px; margin-bottom: 15px;">Got your message. I'll try to respond asap.</p>
                <p style="font-size: 16px; margin-bottom: 15px;">Pekny den</p>
                <p style="font-size: 16px; color: #C1E1C1; font-style: italic;">D</p>
              </div>
            </div>
          `
        }
      });
      
      setFormData({ name: '', email: '', message: '' });
      setStatus({
        type: 'success',
        message: 'Thank you for your message. You should receive an automatic reply in your email soon.'
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatus({
        type: 'error',
        message: 'There was an error sending your message. Please try again.'
      });
    }
  };

  return (
    <div className="page-wrapper">
      <ParticleWave />
      <div className="contact-container">
        <div className="glass-effect">
          <h1 className="contact-title">Get in Touch</h1>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name" className="label-bebas">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Your name"
                className="glass-input"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email" className="label-bebas">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="your.email@example.com"
                className="glass-input"
              />
            </div>

            <div className="form-group">
              <label htmlFor="message" className="label-bebas">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="Your message"
                rows="5"
                className="glass-input text-lato"
              />
            </div>

            <button type="submit" className="submit-button">
              Send Message
            </button>

            {status.message && (
              <div className={`status-message ${status.type}`}>
                {status.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

