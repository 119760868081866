import React, { useState } from 'react';
import { Edit3, Eye, Trash2 } from 'lucide-react';
import './styles.css';

const MOCK_POSTS = [
  {
    id: '1',
    title: 'Getting Started with React',
    summary: 'Learn the basics of React development',
    status: 'published',
    category: 'Development',
    publishedAt: new Date('2024-01-15'),
    content: 'Sample content here...'
  },
  {
    id: '2',
    title: 'Web Development Best Practices',
    summary: 'Essential tips for modern web development',
    status: 'draft',
    category: 'Tutorial',
    publishedAt: new Date('2024-01-20'),
    content: 'Sample content here...'
  }
];

const BlogDashboard = () => {
  const [posts, setPosts] = useState(MOCK_POSTS);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterCategory, setFilterCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const categories = ['all', ...new Set(posts.map(post => post.category))];

  const handleDeleteClick = (post) => {
    setSelectedPost(post);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    setPosts(posts.filter(post => post.id !== selectedPost.id));
    setShowDeleteConfirm(false);
    setSelectedPost(null);
  };

  const formatDate = (date) => {
    return date.toLocaleDateString();
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'published': return 'status-published';
      case 'draft': return 'status-draft';
      case 'scheduled': return 'status-scheduled';
      default: return 'status-draft';
    }
  };

  const filteredPosts = posts.filter(post => {
    if (filterStatus !== 'all' && post.status !== filterStatus) return false;
    if (filterCategory !== 'all' && post.category !== filterCategory) return false;
    if (searchTerm && !post.title.toLowerCase().includes(searchTerm.toLowerCase())) return false;
    return true;
  });

  return (
    <div className="blog-dashboard">
      {/* Header */}
      <div className="dashboard-header">
        <h1 className="dashboard-title">Blog Posts</h1>
        <button className="new-post-button">New Post</button>
      </div>

      {/* Filters */}
      <div className="filters-container">
        <input
          type="text"
          placeholder="Search posts..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="filter-input"
        />
        
        <select 
          value={filterStatus} 
          onChange={(e) => setFilterStatus(e.target.value)}
          className="filter-select"
        >
          <option value="all">All Status</option>
          <option value="published">Published</option>
          <option value="draft">Draft</option>
          <option value="scheduled">Scheduled</option>
        </select>

        <select 
          value={filterCategory} 
          onChange={(e) => setFilterCategory(e.target.value)}
          className="filter-select"
        >
          {categories.map(category => (
            <option key={category} value={category}>
              {category === 'all' ? 'All Categories' : category}
            </option>
          ))}
        </select>
      </div>

      {/* Error Message */}
      {error && (
        <div className="error-message">{error}</div>
      )}

      {/* Posts Table */}
      {loading ? (
        <div className="loading-state">Loading...</div>
      ) : (
        <div className="posts-table-container">
          <table className="posts-table">
            <thead>
              <tr className="table-header">
                <th>Title</th>
                <th>Status</th>
                <th>Category</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredPosts.map(post => (
                <tr key={post.id} className="table-row">
                  <td className="table-cell">
                    <div className="post-title">{post.title}</div>
                    <div className="post-summary">{post.summary}</div>
                  </td>
                  <td className="table-cell">
                    <span className={`status-badge ${getStatusClass(post.status)}`}>
                      {post.status}
                    </span>
                  </td>
                  <td className="table-cell">{post.category}</td>
                  <td className="table-cell">{formatDate(post.publishedAt)}</td>
                  <td className="table-cell">
                    <div className="actions-container">
                      <button className="action-button">
                        <Edit3 size={18} />
                      </button>
                      <button className="action-button">
                        <Eye size={18} />
                      </button>
                      <button 
                        className="action-button delete-button"
                        onClick={() => handleDeleteClick(post)}
                      >
                        <Trash2 size={18} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3 className="modal-title">Delete Post</h3>
            <p>Are you sure you want to delete "{selectedPost?.title}"?</p>
            <div className="modal-actions">
              <button 
                className="modal-button modal-cancel"
                onClick={() => setShowDeleteConfirm(false)}
              >
                Cancel
              </button>
              <button 
                className="modal-button modal-delete"
                onClick={handleConfirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogDashboard;