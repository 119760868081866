import React, { useState, useEffect } from 'react';
import { auth, provider } from '../firebase';
import { signInWithPopup, signOut } from 'firebase/auth';

const AdminAuth = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Auth error:', error);
      alert('Authentication failed: ' + error.message);
    }
  };

  const handleSignOut = () => signOut(auth);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return (
      <div style={{ 
        maxWidth: '600px', 
        margin: '40px auto', 
        padding: '20px',
        textAlign: 'center' 
      }}>
        <h1>Admin Access Required</h1>
        <button
          onClick={handleSignIn}
          style={{
            padding: '10px 20px',
            backgroundColor: '#333',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px'
          }}
        >
          Sign in with Google
        </button>
      </div>
    );
  }

  return (
    <div>
      <div style={{ 
        padding: '10px', 
        backgroundColor: '#f5f5f5',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <span>Signed in as: {user.email}</span>
        <button
          onClick={handleSignOut}
          style={{
            padding: '5px 10px',
            backgroundColor: '#333',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Sign Out
        </button>
      </div>
      {children}
    </div>
  );
};

export default AdminAuth;