import React from 'react';
import './Header.css';
import kiss from '../Assets/Images/kiss.webp';
import logo from '../Assets/Images/logo_love-heart.png';

const Header = () => {
  return (
    <header className="hero">
      <img src={logo} alt="Love Heart Logo" className="logo" />
      <div className="hero-image">
        <img src={kiss} alt="Kissing couple" />
      </div>
      <div className="hero-content">
        <div className="hero-text">
          <div className="hero-left">
            <div className="hero-title-group">
              <h1 className="hero-title">MY LEG OVER YOURS</h1>
              <h2 className="hero-subtitle fade-in">Your Leg Over Mine</h2>
            </div>
          </div>
          <div className="hero-right">
            <h5 className="hero-caption">L, inspired by you</h5>
            {/*<form className="signup-form">
              <input type="email" placeholder="Enter your email" required />
              <button type="submit">SIGN UP</button>
            </form>
            <p className="terms">By clicking Sign Up you're confirming that you agree with our Terms and Conditions.</p>*/}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;