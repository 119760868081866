import matter from 'gray-matter';
import { Marked } from 'marked';
import { Buffer } from 'buffer';

global.Buffer = Buffer;

const inlineMarked = new Marked({
  breaks: true,
  gfm: true,
});

const parseContent = (content) => {
  // Add a function to decode HTML entities
  const decodeHtmlEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  const lines = content.split('\n');
  let html = '';

  for (const line of lines) {
    const trimmedLine = line.trim();
    if (!trimmedLine) continue;

    if (trimmedLine.startsWith('#')) {
      const level = trimmedLine.match(/^#+/)[0].length;
      const headerText = trimmedLine.replace(/^#+\s*/, '').trim();
      html += `<h${level} class="question-title">${headerText}</h${level}>\n`;
      continue;
    }

    if (trimmedLine.startsWith('Q:')) {
      const questionText = trimmedLine.substring(2).trim();
      html += `<div class="question">${questionText}</div>\n`;
      continue;
    }

    if (trimmedLine.startsWith('@')) {
      const dateText = trimmedLine.substring(1).trim();
      html += `<div class="date-marker">${dateText}</div>\n`;
      continue;
    }

    if (trimmedLine.startsWith('L:') || trimmedLine.startsWith('D:')) {
      const speaker = trimmedLine.charAt(0);
      const message = trimmedLine.substring(2).trim();
      html += `<div class="dialogue"><p class="dialogue-${speaker}">${message}</p></div>\n`;
      continue;
    }

   // For regular text, preserve apostrophes
   html += `<p>${inlineMarked.parse(trimmedLine).replace(/&#39;/g, "'")}</p>\n`;
  }

  return html;
};

export const parseMarkdown = (markdown) => {
  try {
    // 1. First separation
    console.log('Raw markdown input:', markdown); // Add this to see what's coming in
    const { data: frontMatter, content: mainContent } = matter(markdown);
    
    console.log('After matter separation:');
    console.log('Frontmatter:', JSON.stringify(frontMatter, null, 2));
    console.log('Main content:', mainContent);
    
    // 2. Content processing
    const htmlContent = parseContent(mainContent);
    console.log('After parseContent:', htmlContent);

    const metadata = {
      title: frontMatter?.title ?? '',
      subtitle: frontMatter?.subtitle ?? '',
      summary: frontMatter?.summary ?? '',
      slug: frontMatter?.slug ?? '',
      category: frontMatter?.category ?? '',
      status: frontMatter?.status ?? 'draft',
      imageURL: frontMatter?.imageURL ?? '',
      readTime: frontMatter?.readTime ?? 3,
      tags: frontMatter?.tags ?? [],
      createdAt: frontMatter?.createdAt ?? null,
      updatedAt: frontMatter?.updatedAt ?? null,
      publishedAt: frontMatter?.publishedAt ?? null,
      scheduledFor: frontMatter?.scheduledFor ?? null,
      views: frontMatter?.views ?? 0,
    };

    console.log('Final metadata:', JSON.stringify(metadata, null, 2)); // Add this
    
    return { metadata, content: htmlContent };
  } catch (error) {
    console.error('Error parsing markdown:', error);
    throw error;
  }
};