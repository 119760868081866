import React from 'react';
import { useParams } from 'react-router-dom';
import { stories } from '../data/stories';
import './StoryPage.css';

const StoryPage = () => {
  const { id } = useParams();
  const story = stories.find(s => s.id === parseInt(id));

  if (!story) return <div>Story not found</div>;

  return (
    <div className="story-page">
      <div className="story-container">
        <h1 className="story-title">{story.title}</h1>
        <div className="story-image-container">
          <img src={story.imageUrl} alt={story.title} className="story-image" />
        </div>
        <div className="story-page-content">  {/* Changed from story-content */}
          {story.isPoem ? (
            story.content.map((line, index) => (
              <div key={index} className="poem-line">
                {line}
              </div>
            ))
          ) : (
            story.content.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default StoryPage;