import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';



const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  

  const scrollToStories = (event) => {
    event.preventDefault();
    const storiesSection = document.getElementById('stories-section');
    if (storiesSection) {
      storiesSection.scrollIntoView({ behavior: 'smooth' });
    } else if (location.pathname !== '/') {
      window.location.href = '/#stories-section';
    }
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const closeMenu = () => setIsMenuOpen(false);
    window.addEventListener('resize', closeMenu);
    return () => window.removeEventListener('resize', closeMenu);
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">My Leg Over Yours</Link>
        <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link></li>
          <li><a href="/#stories-section" onClick={scrollToStories}>Stories</a></li>
          <li><Link to="/about" onClick={() => setIsMenuOpen(false)}>About</Link></li>
          {/* Add the new Blog link here */}
          <li><Link to="/blog" onClick={() => setIsMenuOpen(false)}>Blog</Link></li>
          <li><Link to="/contact" onClick={() => setIsMenuOpen(false)}>Contact</Link></li>
          {/*<li className="dropdown">
            <span onClick={() => toggleDropdown('resources')}>Resources</span>
            <ul className={`dropdown-content ${activeDropdown === 'resources' ? 'active' : ''}`}>
              <li><Link to="/books" onClick={() => setIsMenuOpen(false)}>Books</Link></li>
              <li><Link to="/products" onClick={() => setIsMenuOpen(false)}>Products</Link></li>
              <li><Link to="/links" onClick={() => setIsMenuOpen(false)}>Useful Links</Link></li>
            </ul>
          </li>*/}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;