import React, { useState, useEffect, useRef } from 'react';
import matter from 'gray-matter';
import { parseMarkdown } from '../utils/markdownParser';
import { savePost, updatePost } from '../utils/postOperations';
import { uploadImage, validateImage } from '../utils/imageUpload';
import AdminAuth from './AdminAuth';
import './BlogPost.css';

const MarkdownTest = () => {
  const [markdown, setMarkdown] = useState(`---
title: "Pink Panther"
subtitle: "The first adventure"
summary: ""
slug: "pink-panther"
category: ""
tags: []
status: draft
imageURL: ""
readTime: 3
views: 0
---

# The Pink Panther

Content goes here...`);

  const [parsed, setParsed] = useState(null);
  const [savedPostId, setSavedPostId] = useState(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [scheduledFor, setScheduledFor] = useState('');
  const [postStatus, setPostStatus] = useState('draft');
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    handleParse();
    const textOnly = markdown.replace(/---[\s\S]*?---/, '');
    const words = textOnly.trim().split(/\s+/).length;
    setWordCount(words);
  }, [markdown]);

  const handleParse = () => {
    try {
      const result = parseMarkdown(markdown);
      console.log('Parse result:', result);
      setParsed(result);
      setError(null);
    } catch (err) {
      console.error('Parsing error:', err);
      setError('Error parsing markdown: ' + err.message);
    }
  };

  const updateMarkdownMetadata = (updates) => {
    try {
      const { data: existingFrontMatter, content: mainContent } = matter(markdown);
      
      console.log('Existing frontmatter:', existingFrontMatter);
      
      // Create updated metadata object, preserving all existing metadata
      const newMetadata = {
        title: existingFrontMatter.title || '',
        subtitle: existingFrontMatter.subtitle || '',
        summary: existingFrontMatter.summary || '',
        slug: existingFrontMatter.slug || '',
        category: existingFrontMatter.category || '',
        tags: existingFrontMatter.tags || [],
        status: updates.status || existingFrontMatter.status || 'draft',
        imageURL: existingFrontMatter.imageURL || '',
        readTime: existingFrontMatter.readTime || 3,
        views: existingFrontMatter.views || 0,
        createdAt: existingFrontMatter.createdAt || null,
        updatedAt: new Date().toISOString(),
        publishedAt: updates.publishedAt || existingFrontMatter.publishedAt || null,
        scheduledFor: updates.scheduledFor || existingFrontMatter.scheduledFor || null,
        ...updates
      };
  
      // Build new frontmatter section with proper string formatting
      const newFrontMatter = ['---']
        .concat(Object.entries(newMetadata)
          .filter(([key, value]) => value !== undefined && value !== null && value !== '')
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              return `${key}: [${value.join(', ')}]`;
            }
            // Add quotes around string values that might contain special characters
            if (typeof value === 'string' && (value.includes(':') || value.includes(' '))) {
              return `${key}: "${value.replace(/"/g, '\\"')}"`;
            }
            return `${key}: ${value}`;
          })
        )
        .concat(['---'])
        .join('\n');
  
      // Ensure only one frontmatter section
      const contentStart = markdown.indexOf('---', 3) + 4;
      let cleanContent = markdown.slice(contentStart).trim();
      
      // Remove any additional frontmatter sections
      cleanContent = cleanContent.replace(/^---[\s\S]*?---\n/, '');
  
      console.log('New frontmatter:', newFrontMatter);
      console.log('Clean content:', cleanContent);
  
      return `${newFrontMatter}\n\n${cleanContent}`;
    } catch (error) {
      console.error('Error updating metadata:', error);
      return markdown;
    }
  };
  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setPostStatus(newStatus);
    
    console.log('Status changing to:', newStatus);
    
    const metadata = {
      status: newStatus,
      ...(newStatus === 'published' ? { 
        publishedAt: new Date().toISOString(),
        scheduledFor: ''
      } : newStatus === 'scheduled' ? {
        publishedAt: '',
        scheduledFor: scheduledFor
      } : {
        publishedAt: '',
        scheduledFor: ''
      })
    };
  
    console.log('Metadata updates to be applied:', metadata);
  
    const updatedMarkdown = updateMarkdownMetadata(metadata);
    console.log('Final markdown after update:', updatedMarkdown);
    
    setMarkdown(updatedMarkdown);
  };
  const handleSave = async () => {
    try {
      setSaving(true);
      setError(null);
      
      const result = await savePost(markdown, { status: postStatus });
      console.log('Saved post:', result);
      setSavedPostId(result.id);
      alert(`Post saved successfully! ID: ${result.id}`);
    } catch (err) {
      console.error('Save error:', err);
      setError('Error saving post: ' + err.message);
    } finally {
      setSaving(false);
    }
  };

  const handleSchedule = async () => {
    try {
      if (!scheduledFor) {
        setError('Please select a publication date');
        return;
      }

      setSaving(true);
      setError(null);
      
      const scheduleDate = new Date(scheduledFor);
      if (scheduleDate <= new Date()) {
        setError('Schedule date must be in the future');
        return;
      }

      const updatedMarkdown = updateMarkdownMetadata({
        status: 'scheduled',
        scheduledFor: scheduleDate.toISOString()
      });

      const result = await savePost(updatedMarkdown, {
        status: 'scheduled',
        scheduledFor: scheduleDate
      });

      console.log('Post scheduled:', result);
      setSavedPostId(result.id);
      setMarkdown(updatedMarkdown);
      alert(`Post scheduled for ${scheduleDate.toLocaleString()}`);
    } catch (err) {
      console.error('Scheduling error:', err);
      setError('Error scheduling post: ' + err.message);
    } finally {
      setSaving(false);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    try {
      setUploading(true);
      validateImage(file);
      
      const { url, path } = await uploadImage(file);
      
      const safeName = file.name.replace(/[^a-zA-Z0-9-_]/g, '');
      const imageMarkdown = `\n![${safeName}](${url})\n`;
      const updatedMarkdown = updateMarkdownMetadata({
        imageURL: path
      });
      
      const textArea = document.querySelector('textarea');
      const cursorPosition = textArea.selectionStart;
      const beforeText = updatedMarkdown.substring(0, cursorPosition);
      const afterText = updatedMarkdown.substring(cursorPosition);
      
      setMarkdown(`${beforeText}${imageMarkdown}${afterText}`);
      
    } catch (error) {
      setError(error.message);
      console.error('Upload error:', error);
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <AdminAuth>
      <div className="blog-post" style={{ 
        padding: '2rem',
        maxWidth: '1600px',
        margin: '0 auto',
        minHeight: '100vh'
      }}>
        <h1 style={{ 
          marginBottom: '2rem',
          color: '#C1E1C1',
          fontSize: '2.5rem',
          textAlign: 'center',
          fontFamily: 'Bebas Neue, sans-serif'
        }}>
          MARKDOWN POST EDITOR
        </h1>
        
        <div style={{ 
          display: 'flex', 
          gap: '2rem', 
          marginBottom: '1rem',
          height: '70vh',
          minHeight: '600px'
        }}>
          {/* Editor Side */}
          <div style={{ flex: 1 }}>
            <h2 style={{ 
              marginBottom: '1rem',
              fontFamily: 'BEBAS NEUE, sans-serif',
              color: '#555',
              letterSpacing: '2.0px',
              fontWeight: 300
            }}>Editor</h2>
            <div style={{ 
              marginBottom: '1rem',
              padding: '0.5rem',
              backgroundColor: '#FAF9F6',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center'
            }}>
              <span>Word count: {wordCount} (~{Math.ceil(wordCount / 200)} min read)</span>
              <button
                onClick={() => fileInputRef.current?.click()}
                style={{
                  padding: '0.5rem 1rem',
                  backgroundColor: '#C1E1C1',
                  color: 'black',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: uploading ? 'not-allowed' : 'pointer',
                  opacity: uploading ? 0.7 : 1,
                  fontFamily: 'BEBAS NEUE, sans-serif',
                  marginLeft: '1rem'
                }}
                disabled={uploading}
              >
                {uploading ? 'Uploading...' : 'Add Image'}
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageUpload}
                accept="image/jpeg,image/png,image/webp"
                style={{ display: 'none' }}
              />
            </div>
            <textarea
              value={markdown}
              onChange={(e) => setMarkdown(e.target.value)}
              style={{
                width: '100%',
                height: 'calc(100% - 100px)',
                padding: '1rem',
                fontFamily: 'monospace',
                border: '1px solid #ccc',
                borderRadius: '4px',
                resize: 'none',
                fontSize: '14px',
                lineHeight: '1.5',
                marginTop: '1rem'
              }}
            />
          </div>

          {/* Preview Side */}
          <div style={{ flex: 1 }}>
            <h2 style={{ 
              marginBottom: '1rem',
              fontFamily: 'BEBAS NEUE, sans-serif',
              letterSpacing: '2.0px',
              fontWeight: 300
            }}>Preview</h2>
            {parsed && (
              <div style={{ 
                height: 'calc(100% - 60px)',
                overflowY: 'auto',
                padding: '1rem',
                border: '1px solid #ccc',
                borderRadius: '4px',
                backgroundColor: 'white',
                marginTop: '3.5rem'
              }}>
                <div 
                  dangerouslySetInnerHTML={{ __html: parsed.content }}
                  className="blog-post-content"
                />
              </div>
            )}
          </div>
        </div>
        
        {/* Action Buttons */}
        <div style={{ 
          marginTop:'2rem',
          marginBottom: '3rem',
          display: 'flex', 
          gap: '1rem',
          borderTop: '2px solid #333',
          paddingTop: '4.5rem',
          alignItems: 'center'
        }}>
          <button
            onClick={postStatus === 'scheduled' ? handleSchedule : handleSave}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#333',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: saving ? 'not-allowed' : 'pointer',
              opacity: saving ? 0.7 : 1,
              fontFamily: 'BEBAS NEUE, sans-serif'
            }}
            disabled={saving}
          >
            {saving ? 'Saving...' : 
             postStatus === 'scheduled' ? 'Schedule Post' : 
             postStatus === 'published' ? 'Publish Post' : 
             'Save Draft'}
          </button>

          <select 
            value={postStatus}
            onChange={handleStatusChange}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#dd7973',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              fontFamily: 'BEBAS NEUE, sans-serif',
              letterSpacing: '1.5px',
              fontWeight: '300',
              cursor: 'pointer'
            }}
          >
            <option value="draft">Draft</option>
            <option value="scheduled">Schedule</option>
            <option value="published">Publish</option>
          </select>

          {postStatus === 'scheduled' && (
            <input
              type="datetime-local"
              value={scheduledFor}
              onChange={(e) => setScheduledFor(e.target.value)}
              min={new Date().toISOString().slice(0, 16)}
              style={{
                padding: '0.5rem',
                border: '1px solid #ccc',
                borderRadius: '4px',
                fontFamily: 'BEBAS NEUE, sans-serif'
              }}
            />
          )}
        </div>

        {/* Error Display */}
        {error && (
          <div style={{ 
            font: 'lato',
            color: 'red', 
            padding: '1rem', 
            marginBottom: '1rem', 
            backgroundColor: '#ffebee',
            borderRadius: '4px'
          }}>
            {error}
          </div>
        )}

        {/* Metadata Preview */}
        {parsed && (
          <div style={{ marginTop: '2rem' }}>
            <h3>Metadata:</h3>
            <pre style={{ 
              backgroundColor: '#f5f5f5', 
              padding: '1rem',
              borderRadius: '4px',
              overflow: 'auto'
            }}>
              {JSON.stringify(parsed.metadata, null, 2)}
            </pre>
          </div>
        )}
      </div>
    </AdminAuth>
  );
};

export default MarkdownTest;