import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import './BlogList.css';

const BlogList = () => {
  const [featuredPost, setFeaturedPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState('View all');
  const [sortOrder, setSortOrder] = useState('newest');
  const [loading, setLoading] = useState(true);

  const formatDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      const date = timestamp.toDate();
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
    return 'No date';
  };

  const fetchPosts = async () => {
    setLoading(true);
    try {
      let q;
      switch (sortOrder) {
        case 'oldest':
          q = query(
            collection(db, 'mloy-content'),
            where('status', '==', 'published'),
            orderBy('publishedAt', 'asc')
          );
          break;
        case 'title':
          q = query(
            collection(db, 'mloy-content'),
            where('status', '==', 'published'),
            orderBy('title', 'asc')
          );
          break;
        case 'newest':
        default:
          q = query(
            collection(db, 'mloy-content'),
            where('status', '==', 'published'),
            orderBy('publishedAt', 'desc')
          );
      }

      const querySnapshot = await getDocs(q);
      const fetchedPosts = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const postDate = data.publishedAt || data.date;
        let imageURL = data.imageURL || null;

        return {
          id: doc.id,
          ...data,
          date: postDate ? formatDate(postDate) : 'No date',
          imageURL,
        };
      });

      if (fetchedPosts.length > 0) {
        setFeaturedPost(fetchedPosts[0]);
        setPosts(fetchedPosts.slice(1));
      } else {
        setFeaturedPost(null);
        setPosts([]);
      }

      const uniqueCategories = [...new Set(fetchedPosts.map((post) => post.category))];
      setCategories(['View all', ...uniqueCategories]);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [sortOrder]);

  if (loading) {
    return <div>Loading posts...</div>;
  }

  return (
    <div className="blog-list">
      <h1 className="blog-main-title">What just happened?</h1>
      <p className="blog-intro">
        When it comes to investing time in getting to know
        myself, I tend to stick well clear. Here, I'm documenting my journey to find out why I can't form healthy relationships and why, the impact of that one other things, and see if can change. And other stuff.
      </p>

      <div className="sort-controls">
        <select
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
          className="sort-select"
        >
          <option value="newest">Newest First</option>
          <option value="oldest">Oldest First</option>
          <option value="title">By Title</option>
        </select>
      </div>

      <div className="categories">
        {categories.map((category) => (
          <button
            key={category}
            className={`category-btn ${activeCategory === category ? 'active' : ''}`}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {featuredPost && (
        <div className="featured-post">
          <div className="featured-image">
            {featuredPost.imageURL ? (
              <img
                src={featuredPost.imageURL}
                alt={featuredPost.title}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/placeholder-image.jpg';
                }}
              />
            ) : (
              <div className="placeholder-image">No image available</div>
            )}
          </div>
          <div className="featured-content">
            <span className="blog-category">{featuredPost.category}</span>
            <h2 className="blog-featured-title">{featuredPost.title}</h2>
            <p>{featuredPost.summary}</p>
            <div className="post-info">
              <span>
                {featuredPost.date} • {featuredPost.readTime} min read
              </span>
            </div>
            <Link to={`/blog/${featuredPost.id}`} className="blog-button">
              Read more
            </Link>
          </div>
        </div>
      )}

      <div className="posts-grid">
        {posts.map((post) => (
          <div key={post.id} className="post-preview">
            <div className="post-image">
              {post.imageURL ? (
                <img
                  src={post.imageURL}
                  alt={post.title}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/placeholder-image.jpg';
                  }}
                />
              ) : (
                <div className="placeholder-image">No image available</div>
              )}
            </div>
            <div className="post-content">
              <span className="blog-category">{post.category}</span>
              <h3 className="post-preview-title">{post.title}</h3>
              <p>{post.summary}</p>
              <div className="post-info">
                <span>
                  {post.date} • {post.readTime} min read
                </span>
              </div>
              <Link to={`/blog/${post.id}`} className="blog-button">
                Read more
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
